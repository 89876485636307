a {
  text-decoration: none;
  color: inherit;
}
.logo {
  color: #fff !important;
}
.nav-links-main {
  justify-content: center;
}
.logo span {
  color: #f77b6b !important;
}

.effect-underline {
  position: relative;
  color: #fff !important;
  transition: 0.5s;
}

.effect-underline:hover {
  color: #f77b6b !important;
}

.industry-main {
  cursor: pointer;
}

.effect-underline:after {
  content: "";
  position: absolute;
  left: 0;
  display: inline-block;
  height: 1em;
  width: 100%;
  border-bottom: 1px solid;
  margin-top: 10px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}

.effect-underline:hover:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.humburger {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.humburger span {
  height: 2px;
  width: 20px;
  background-color: #fff;
  border-radius: 10px;
}
.humMain {
  justify-content: center;
  align-items: center;
  display: none;
}

